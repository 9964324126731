export interface ILogin {
    readonly id: any;
    name: string
    description: string
}

export class Login {
    public id!: any;
    public email: string;
    public password: string;
}